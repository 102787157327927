import React, { useState, useEffect } from "react";
import { Form, Button,InputGroup, Modal   } from "react-bootstrap";
import axios from "axios";

import '../App.css';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function Batuan() {
  const [ID, setID] = useState('');
  const [BatuanData, setBatuanData] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');

  useEffect(() => {
    document.title = "Digital Outcrop | Lithology ";
    getBatuan();
  }, []);
  
  useEffect(() => {
    getBatuan();
  }, [currentPage]);

      // Variable Input
      const [Batuan, setBatuan] = useState('');
      const [BatuanColor, setBatuanColor] = useState('');


      // dapatkan data Batuan 
      const getBatuan = async () => {

        const response = await axios.get(`/Batuan?page=${currentPage}&limit=${limit}`);

        if (response.data.length) {
          setBatuanData(response.data[0]);
          setTotal(Math.ceil(response.data[1].jumlah / limit));
          // setTotal(Math.ceil(response.data[1].jumlah / limit));
          console.log(Total);
        }
      }

      //Add new Batuan
      const [newBatuanModal, setnewBatuan] = useState(false);
      const newBatuanModalClose = () => setnewBatuan(false);
      const newBatuanModalShow = () => {
        setnewBatuan(true);
        setID('');
        setBatuan('');
        setBatuanColor('');
      }

      
        // tambah Batuan
        const Tambah = async (e) => {
          e.preventDefault();
          await axios.post('/batuan', {
            batuan: Batuan,
            color: BatuanColor,
          });
          getBatuan();
          newBatuanModalClose();
        }

      // Edit Batuan 
      const [editBatuanModal, seteditBatuan] = useState(false);
      const editClose = () => {
        seteditBatuan(false);
        setID('');
        setBatuan('');
        setBatuanColor('');
      }

      const editShow = (data) => {
        seteditBatuan(true);
        console.log(data);
        setID(data.ID);
        setBatuan(data.batuan);
        setBatuanColor(data.color);

      }

      const Edit = async (e) => {
        e.preventDefault();
        await axios.put(`/batuan/${ID}`, {
          batuan: Batuan,
          color: BatuanColor,
        });
        getBatuan();
        editClose();
      }



    // hapus data Batuan 
    // Modal
    const [deleteModal, setdetele] = useState(false);
    const deleteModalClose = () => {
      setdetele(false);
      setID('');
    }
      
    const deleteModalShow = (ID) => {
      setdetele(true);
      setID(ID);
    }

    const Hapus = async (e) => {
      e.preventDefault();
      await axios.delete('/Batuan/' + ID);
      getBatuan();
      setdetele(false);
    }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/Batuan/pencarian?page=${currentPage}&limit=${limit}&key=${key}`);
      if (response.data.length) {
        setBatuanData(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    } else {
      const response = await axios.get(`/Batuan?page=${currentPage}&limit=${limit}`);
      if (response.data.length) {
        setBatuanData(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    }
  }
        
// handle pagination
const Pagination = (page) => {
  console.log(page);
  setCurrentPage(page);
  // await getBatuan(page); // Wait for getBatuan to complete before updating the currentPage state
}

// membuat list item pagination
const pageNumbers = [];
for (let i = 1; i <= Total; i++) {
  pageNumbers.push(
    <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
  );
}

  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
        <div className="row justifty-content-center">
          <div className="col-12 MenuContainerLog ps-3">
          <h3 className="subJudul ms-1"><b>Lithology</b></h3>
          <div className="container">
            <div className="row " style={{marginLeft: '-15px'}}>
              <Form onSubmit={filter}>
                    <div className="col-3">
                      <InputGroup className="mb-1 ">
                        <Form.Control
                          className="CrudSearch"
                          placeholder="Search ..."
                          aria-label="Cari"
                          aria-describedby="basic-addon2"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                          <Button className="btn btn-secondary ms-2 mt-1 btnSearch" type="submit">Search</Button>
                      </InputGroup>
                    </div>
                  </Form>
              <div className="col">
              <button type="button" className="btn btn-success ms-1"  onClick={newBatuanModalShow} >Add New Lithology </button>
              </div>
            </div>
          </div>
            <div className="card m-2" style={{background: '#fff'}}>
              <div className="card-body">
              <div className="table-responsive">
                <table className="table text-center">
                  <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Lithology</th>
                          <th scope="col">Color</th>
                          <th scope="col">Action</th>
                        </tr>
                  </thead>
                  <tbody>

                  {!!BatuanData && BatuanData ? (
                      BatuanData.map((item, i) => (                   
                    <tr>
                      
                      <th key={item.ID} width="150" scope="row">{(currentPage - 1) * limit + i + 1}</th>

                      <td >{item.batuan}</td>
                      <td width="300" style={{backgroundColor: item.color,textAlign:'center'}}>
                        {/* Background Batuan menurut Hex Color */}
                      </td>
                      <td  width="300">
                        <button type="button" className="btn btn-success me-1"  onClick={() => editShow(item)}>Edit</button>
                        <button type="button" className="btn btn-danger me-1"  onClick={() => deleteModalShow(item.ID)}>Delete</button>
                      </td>
                      
                    </tr>
                    ))) : (<div>Tidak ada data</div>)}
                  </tbody>
                </table>
                </div>
              </div>
            </div>        
                <div className="pagination ms-2">
                  <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                  {pageNumbers}
                  <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
                </div>
            </div>
        </div>
        </div>
      </div>
      <Footer />
          {/* Modal Delete */}
          <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
            <Form className="form-box" onSubmit={Hapus}>
              <Modal.Header closeButton>
                <Modal.Title> Delete Log</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <h5 className='textDeleteModal'>
                Are you sure to delete Log data ?
              </h5>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={deleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Add Modal */}
          <Modal show={newBatuanModal} onHide={newBatuanModalClose} size="lg">
            <Form className="form-box" onSubmit={Tambah}>
              <Modal.Header closeButton>
                <Modal.Title>Add New Lithology Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="card ">
                <div className="card-body p-4">
                <div className="form-group">
                
                <label>Lithology Name</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    className="searchInput"
                    placeholder="Name"
                    aria-label="Name"
                    aria-describedby="basic-addon2"
                    value={Batuan}
                    onChange={(e) => setBatuan(e.target.value)}
                  />
                </InputGroup>
              
                <label>Color</label>
                <InputGroup className="mb-3 ">
                  <Form.Select aria-label="Default select example" value={BatuanColor} onChange={(e) => setBatuanColor(e.target.value)}>
                    <option>Select Color</option>
                    <option value="Red">Red</option>
                    <option value="Blue">Blue</option>
                    <option value="Green">Green</option>
                    <option value="#DEBD1B">Yellow</option>
                    <option value="Grey">Grey</option>
                    <option value="Black">Black</option>
                    <option value="Brown">Brown</option>
                    <option value="Orange">Orange</option>
                    <option value="Purple">Purple</option>
                    <option value="#FFA500">Gold</option>
                    <option value="#00CED1">Turquoise</option>
                    <option value="Pink">Pink</option>
                    <option value="Silver">Silver</option>
                    <option value="#8B4513">Sienna</option>
                    <option value="#4CAF50">Emerald</option>
                    <option value="#FF6347">Tomato</option>
                  </Form.Select>
                </InputGroup>

              </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={newBatuanModalClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Add New Lithology
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Edit Modal */}
          <Modal show={editBatuanModal} onHide={editClose} size="lg">
            <Form className="form-box" onSubmit={Edit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Lithology Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="card ">
                <div className="card-body p-4">
                <div className="form-group">
                
                <label>Lithology Name</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    className="searchInput"
                    placeholder="Name"
                    aria-label="Name"
                    aria-describedby="basic-addon2"
                    value={Batuan}
                    onChange={(e) => setBatuan(e.target.value)}
                  />
                </InputGroup>
              
                <label>Color</label>
                <InputGroup className="mb-3 ">
                  <Form.Select aria-label="Default select example" value={BatuanColor} onChange={(e) => setBatuanColor(e.target.value)}>
                    <option>Select Color</option>
                    <option value="Red">Red</option>
                    <option value="Blue">Blue</option>
                    <option value="Green">Green</option>
                    <option value="#DEBD1B">Yellow</option>
                    <option value="Grey">Grey</option>
                    <option value="Black">Black</option>
                    <option value="Brown">Brown</option>
                    <option value="Orange">Orange</option>
                    <option value="Purple">Purple</option>
                    <option value="#FFA500">Gold</option>
                    <option value="#00CED1">Turquoise</option>
                    <option value="Pink">Pink</option>
                    <option value="Silver">Silver</option>
                    <option value="#8B4513">Sienna</option>
                    <option value="#4CAF50">Emerald</option>
                    <option value="#FF6347">Tomato</option>
                  </Form.Select>
                </InputGroup>

              </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={editClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Edit Lithology Data
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
    </div>
  );
}

export default Batuan;
