import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function Beranda() {
  const [totalUser, setTotalUser] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [totalReport, setTotalReport] = useState(0);
  const [Posting, setPosting] = useState([]);
  const [UserReport, setUserReport] = useState([]);
  useEffect(() => {
    document.title = "MerapiKoding Dashboard | Home";
   
  }, []);
  // dapatkan total user
  const getTotalUser = async () => {
    const response = await axios.get(`/user/total`);
    if (response.data.length) {
      setTotalUser(response.data[0].jumlah);
    }
  }

  // dapatkan total posting
  const getTotalPost = async () => {
    const response = await axios.get(`/posting/total`);
    if (response.data.length) {
      setTotalPost(response.data[0].jumlah);
    }
  }

  // dapatkan total report
  const getTotalReport = async () => {
    const response = await axios.get(`/report/total`);
    if (response.data.length) {
      setTotalReport(response.data[0].jumlah);
    }
  }

  // dapatkan data UserPost 
  const getPosting = async () => {
    const response = await axios.get(`/posting?page=1&limit=5`);
    if (response.data.length) {
      setPosting(response.data[0]);
    }
  }

  // dapatkan data user Report
  const getUserReport = async () => {
    const response = await axios.get('/report?page=1&limit=5');
    if (response.data.length) {
      setUserReport(response.data[0]);
      console.log(response.data);
    }
  }
  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
         

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Beranda;
