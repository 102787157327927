import React, { useState, useEffect } from "react";
import { Form, Button,InputGroup, Modal   } from "react-bootstrap";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import '../App.css';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';


function UserApp() {
  const [ID, setID] = useState('');
  const [UserApp, setUserApp] = useState(null);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');

  useEffect(() => {
    document.title = "Digital Outcrop | User Application";
    getUserApp();
  }, []);

  
  useEffect(() => {
    getUserApp();
  }, [currentPage]);



 // dapatkan data Batuan 
 const getUserApp = async () => {
  const response = await axios.get(`/user?page=${currentPage}&limit=${limit}`);
  if (response.data.length) {
    setUserApp(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }
}

      // Edit Batuan 
      const [Edit, setedit] = useState(false);
      const [StatusUser, setStatusUser] = useState('');

      const editClose = () => {
        setedit(false);
        setID('');
      }

      const editShow = (data) => {
        setedit(true);
        setID(data.Id);
        setStatusUser(data.Status);
      }

      const EditAction = async (e) => {
        e.preventDefault();
        await axios.put(`/userStatus/${ID}`, {
          Status: StatusUser,
        });
        getUserApp();
        editClose();
      }


    // hapus data userApp 
    // Modal
    const [deleteModal, setdetele] = useState(false);
    const deleteModalClose = () => {
      setdetele(false);
      setID('');
    }
      
    const deleteModalShow = (ID) => {
      setdetele(true);
      setID(ID);
    }

    const Hapus = async (e) => {
      e.preventDefault();
      await axios.delete('/user/' + ID);
      getUserApp();
      setdetele(false);
    }


      // Export data ke excel
      const header = ["ID", "Email","First Name","Last Name","Bio","Institution","Img","Status","Link","Role"];

      async function handleDownloadExcel() {
        const response = await axios.get(`/user`);
        const userData = response.data;
        const extractedData = userData.map(item => {
       
          return {
            No: item.Id, // Assuming "id" is a suitable identifier
            Email: item.Email,
            FirstName: item.FirstName,
            LastName: item.LastName,
            Bio: item.Bio,
            Institution: item.Institution,
            Image: item.Img,
            Status: item.Status,
            link: item.Link,
            Role: item.Role
          };
        });

        const dataExtracted = extractedData.map(item => item);

        var currentDate = new Date();
        var sheetName = `DIGITAL OUTCROP-USER LIST-${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

        downloadExcel({
          fileName: sheetName,
          sheet: sheetName,
          tablePayload: {
            header,
            // accept two different data structures
            body: dataExtracted ,
          },
        });
      }

    // filter data dalam pencarian
    const filter = async (e) => {
      e.preventDefault();
      if (key !== '') {
        const response = await axios.get(`/user/pencarian?page=${currentPage}&limit=${limit}&key=${key}`);
        if (response.data.length) {
          setUserApp(response.data[0]);
          setTotal(Math.ceil(response.data[1].jumlah / limit));
        }
      } else {
        const response = await axios.get(`/user?page=${currentPage}&limit=${limit}`);
        if (response.data.length) {
          setUserApp(response.data[0]);
          setTotal(Math.ceil(response.data[1].jumlah / limit));
        }
      }
    }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
  }


  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
    );
  }

  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
        <div className="row justifty-content-center">
          <div className="col-12 MenuContainerLog ps-3">
          <h3 className="subJudul ms-1"><b>User Application</b></h3>
          <div className="container">
            <div className="row " style={{marginLeft: '-15px'}}>
              <Form onSubmit={filter}>
                <div className="col-3">
                  <InputGroup className="mb-1 ">
                    <Form.Control
                      className="CrudSearch"
                      placeholder="Search ..."
                      aria-label="Cari"
                      aria-describedby="basic-addon2"
                      value={key}
                      onChange={(e) => setKey(e.target.value)}
                    />
                    <Button className="btn btn-secondary ms-2 btnSearch" type="submit">Search</Button>
                  </InputGroup>
                </div>
              </Form>
              <div className="col-auto mt-1">
                <button onClick={handleDownloadExcel} type="button" className="btn btn-success ms-1">Export to Excel</button>
              </div>
             
            </div>
          </div>
            <div className="card m-2" style={{background: '#fff'}}>
              <div className="card-body">
              <div className="table-responsive">
                <table className="table text-center">
                  <thead>
                  {/* Id 	Email 	Password 	FirstName 	LastName 	Bio 	Occupation 	Img 	Status 	link 	 */}
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Email</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Institution</th>
                      <th scope="col">Image</th>
                      <th scope="col">Link</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>

                  {!!UserApp && UserApp ? (
                  UserApp.map((item, i) => (         
                    
// Full texts
// Id 	Email 	Password 	FirstName 	LastName 	Bio 	Institution 	Img 	Status 	Link 	Role 	
                    <tr>
                      <th key={item.Id} scope="row">{(currentPage - 1) * limit + i + 1}</th>
                      <td>{item.Email}</td>
                      <td>{item.FirstName}</td>
                      <td>{item.LastName}</td>
                      <td>{item.Institution}</td>
                      <td><img id="image" src={item.Img} className="rounded mt-3" width="65px" alt="" /></td>
                      <td>{item.Link}</td>
                      <td>{item.Status === 1 ? <p style={{color:'green',fontWeight:'bold'}}>User Active</p> :  <p style={{color:'red',fontWeight:'bold'}}>User Banned</p>}</td>
                      <td>    

                      {item.Email != 'admin@phedigitaloutcrop.com' && (
                          <>
                            <button
                              type="button"
                              className="btn btn-success mt-1"
                              onClick={() => editShow(item)}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger ms-3 mt-1"
                              onClick={() => deleteModalShow(item.Id)}
                            >
                              Delete
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))) : (<div>Tidak ada data</div>)}
                  </tbody>
                </table>
                </div>
              </div>
            </div>        
                <div className="pagination ms-2">
                  <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                  {pageNumbers}
                  <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
                </div>
            </div>
        </div>
        </div>
      </div>
      <Footer />

  {/* Delete Modal */}
          <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
            <Form className="form-box" onSubmit={Hapus}>
              <Modal.Header closeButton>
                <Modal.Title> Hapus User</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <h5 className='textDeleteModal'>
                Are you sure to delete user data ?
              </h5>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={deleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Edit Modal */}
          <Modal show={Edit} onHide={editClose} size="sm">
            <Form className="form-box" onSubmit={EditAction}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Status User</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                  
                    <label>Update status</label>
                    <InputGroup className="mb-3 ">
                      <Form.Select aria-label="Default select example" value={StatusUser} onChange={(e) => setStatusUser(e.target.value)}>
                        <option>Select Stauts</option>
                        <option value="1">User Active</option>
                        <option value="0">User Banned</option>
                      </Form.Select>
                    </InputGroup>

             

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={editClose}>
                  Cancel
                </Button>
                <Button variant="success"  type="submit" >
                  Apply
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

    </div>

  );
}

export default UserApp;
