import React, { useState, useEffect } from "react";
import { Form, Button,InputGroup  } from "react-bootstrap";
import axios from "axios";
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';


function Profile() {
  const id_user = localStorage.getItem('id');
  const [nama, setNama] = useState('');
  const [hak_akses, setHakAkses] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    document.title = "Digital Outcrop | User Profile";
    getUser();
  }, []);

  const getUser = async () => {
    const response = await axios.get(`/admin/${id_user}`);
    setNama(response.data.Name);
    setHakAkses(response.data.Akses);
    setEmail(response.data.Email);
  }

  const Edit = async (e) => {
    e.preventDefault();
    await axios.put(`/admin/${id_user}`, {
      Name: nama,
      Akses: hak_akses,
      Email: email,
      Password: password
    });
    getUser();
  }

  return (
    <div className='bg-dashboard'>
    <Header />
    <div className='tinggi-content'>
      <div className='container'>
        <div className="row justify-content-center mt-2">
          <div className="col-sm-7 mb-3 mb-sm-0">
            <h3 className="text-bold"><b>User Profile</b> </h3>
            <div className="card ">
              <div className="card-body p-4">
                <Form onSubmit={Edit} className="form-group">
                  <label>First Name</label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      className="searchInput"
                      placeholder="First Name"
                      aria-label="First Name"
                      aria-describedby="basic-addon2"
                      value={nama}
                      onChange={(e) => setNama(e.target.value)}
                    />
                  </InputGroup>
                
                  <label>Access</label>
                  <InputGroup className="mb-3 ">
                    <Form.Select aria-label="Default select example" value={hak_akses} onChange={(e) => setHakAkses(e.target.value)}>
                      <option>Select Access</option>
                      <option value="admin">Admin</option>
                      <option value="root">Super Admin</option>
                    </Form.Select>
                  </InputGroup>
                
                  <label>Email</label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      className="searchInput"
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon2"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>

                  <label>Password</label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="password" 
                      className="searchInput"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon2"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                
                  <label>Re-Password</label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                    type="password" 
                      className="searchInput"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon2"
                    />
                  </InputGroup>
                  <Button variant="success" type="submit">Save Profile</Button>
                  <Button variant="secondary ms-2">Cancel</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default Profile;
