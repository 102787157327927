import React, { useState, useEffect } from "react";
import { Form, Button, Modal   } from "react-bootstrap";
import '../App.css';
import axios from "axios";
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';


function Visitor() {
  const [VisitorData, setVisitorData] = useState([]);
  const [VisitorTotal, setVisitorTotal] = useState(0);
  const [VisitorThisMonth, setVisitorThisMonth] = useState(0);
  const [ID, setID] = useState('');
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman

    // dapatkan data Batuan 
     const getVisitor = async () => {
      const response = await axios.get(`/Visitor?page=${currentPage}&limit=${limit}`);
      if (response.data.length) {
        setVisitorData(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));      
      }
    }
    // dapatkan data Batuan 
     const getVisitorTotal = async () => {
      const response = await axios.get(`/Visitor`);
      if (response.data.length) {

        let countTotal = 0;
        for (let index = 0; index < response.data.length; index++) {
          countTotal = response.data[index].Count + countTotal;
          console.log(response.data[index].Count);
        }

        setVisitorThisMonth(response.data[response.data.length - 1].Count);
        setVisitorTotal(countTotal)
      }
    }

  
  useEffect(() => {
    document.title = "Digital Outcrop | Visitor";
    getVisitor();
    getVisitorTotal();
  }, []);

       //visitor delete
      // Modal
      const [deleteModal, setdetele] = useState(false);
      const deleteModalClose = () => {
      setdetele(false);
      setID('');
      }
      
      const deleteModalShow = (ID) => {
        
     
      setdetele(true);
      setID(ID);
      }

      const Hapus = (ID) => {
        // return;
        axios.delete('/Visitor/' + ID);
        getVisitor();
        getVisitorTotal();
        setdetele(false);
      }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getVisitor();
  }
  
  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
    );
  }

  return (
    <div className='bg-dashboard'>
    <Header />
    <div className='tinggi-content'>
      <div className='container'>
        <div className="row justify-content-center mt-2">
            <div className="col-sm-4 mb-3 mb-sm-0">
              <div className="card card-bg">
                <div className="card-body">
                  <h5 className="card-title text-bold"><i className="fas fa-user"></i> <b>Visitor This Month</b> </h5>
                  <h1 className="card-text"> <b>{VisitorThisMonth}</b></h1>
                </div>
              </div>
            </div>
          
            <div className="col-sm-4">
              <div className="card card-bg">
                <div className="card-body">
                  <h5 className="card-title"><i className="fas fa-file-alt"></i>  <b>Visitor Total</b></h5>
                  <h1 className="card-text"> <b>{VisitorTotal}</b></h1>
                </div>
              </div>
            </div>
        </div>
        <div className="row mt-3 justify-content-center">
          <div className="col-sm-8">
            <div className="card card-decoration">
              <div className="card-body">
              <table className="table text-center ">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Month</th>
                      <th scope="col">Year</th>
                      <th scope="col">Visitor</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                  {!!VisitorData && VisitorData ? (
                      VisitorData.map((item, i) => (                   
                        <tr>
                        <th  key={item.ID} scope="row">{i + 1}</th>
                        <td>{item.Month}</td>
                        <td>{item.Year}</td>
                        <td>{item.Count}  </td>
                        <td>
                        <button type="button" className="btn btn-danger me-1"  onClick={() => deleteModalShow(item.ID)}>Delete</button>
                          </td>
                      </tr>
                    )
                    )) : (<div>Tidak ada data</div>)}
                  </tbody>
                </table>
              </div>
            </div>
              <div className="pagination ms-2">
                <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                {pageNumbers}
                <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
              </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
        {/* Delete Modal */}
          <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
            <Form className="form-box">
              <Modal.Header closeButton>
                <Modal.Title> Delete Visitor</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <h5 className='textDeleteModal'>
                Are you sure to delete Visitor data ?
              </h5>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={deleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={() => Hapus(ID)}>
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
  </div>
  );
}

export default Visitor;
