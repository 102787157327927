import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

import axios from "axios";
import '../App.css';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function Report() {
  const [ID, setID] = useState('');
  const [UserReport, setUserReport] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  
  useEffect(() => {
    document.title = "Digital Outcrop | Reported User";
    getUserReport();
  }, []);


  
  useEffect(() => {
    getUserReport();
  }, [currentPage]);

    // dapatkan data user Report
    const getUserReport = async () => {
      const response = await axios.get(`/report?page=${currentPage}&limit=${limit}`);
      if (response.data.length) {
        setUserReport(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    }

    // hapus data Report 
    // Modal
    const [deleteModal, setdetele] = useState(false);
    const deleteModalClose = () => {
      setdetele(false);
      setID('');
    }
      
    const deleteModalShow = (ID) => {
      setdetele(true);
      setID(ID);
    }

    const Hapus = async (e) => {
      e.preventDefault();
      await axios.delete('/report/' + ID);
      getUserReport();
      setdetele(false);
    }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/report/pencarian?page=${currentPage}&limit=${limit}&key=${key}`);
      if (response.data.length) {
        setUserReport(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    } else {
      const response = await axios.get(`/report?page=${currentPage}&limit=${limit}`);
      if (response.data.length) {
        setUserReport(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    }
  }

  // handle pagination
const Pagination = async (page) => {
  setCurrentPage(page);
  getUserReport();
}

// membuat list item pagination
const pageNumbers = [];
for (let i = 1; i <= Total; i++) {
  pageNumbers.push(
    <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
  );
}

  return (

    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
          <div className="row justifty-content-center ">
            <div className="col-12 MenuContainerLog ps-3">
              <h3 className="subJudul ms-1"><b>Reported User</b></h3>
              <div className="container">
                <div className="row " style={{ marginLeft: '-15px' }}>
                  <Form onSubmit={filter}>
                    <div className="col-3">
                      <InputGroup className="mb-1 ">
                        <Form.Control
                          className="CrudSearch"
                          placeholder="Search ..."
                          aria-label="Cari"
                          aria-describedby="basic-addon2"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                          <Button className="btn btn-secondary ms-2 btnSearch" type="submit">Search</Button>
                      </InputGroup>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="card m-2" style={{ background: '#fff' }}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table text-center">
                      <thead>
                        {/* Id 	Email 	Password 	FirstName 	LastName 	Bio 	Occupation 	Img 	Status 	link 	 */}
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Email</th>
                          <th scope="col">FirstName</th>
                          <th scope="col">Reason</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {!!UserReport && UserReport ? (
                    UserReport.map((item, i) => (
                      <tr>
                          <th key={item.ID} scope="row">{(currentPage - 1) * limit + i + 1}</th>
                          <td>{item.email}</td>
                          <td>{item.FirstName}</td>
                          <td>{item.reason}</td>
                          <td>
                            <div className="justify-content-right">
                              <Link to={`/ReportDetail/${item.ID}`}>
                                 <a  type="button" className="btn btn-success">Detail</a>
                              </Link>
                              <a type="button" className="btn btn-danger ms-3" onClick={() => deleteModalShow(item.ID)}>Delete</a>
                            </div>
                          </td>
                        </tr>
                          ))) : (<div>Tidak ada data</div>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination ms-2">
                <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                {pageNumbers}
                <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Delete Modal */}
      <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
        <Form className="form-box" onSubmit={Hapus}>
          <Modal.Header closeButton>
            <Modal.Title> Delete Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <h5 className='textDeleteModal'>
              Are you sure to delete Report data ?
            </h5>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={deleteModalClose}>
              Cancel
            </Button>
            <Button variant="danger" type="submit">
              Delete
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </div>
  );
}

export default Report;
