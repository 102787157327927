import React, { useState, useEffect } from "react";
import { Form, Button,InputGroup, Modal   } from "react-bootstrap";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';


function User() {
  const [User, setUser] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    document.title = "Digital Outcrop | User Admin";
    getUser();
  }, []);

  useEffect(() => {
    getUser();
  }, [currentPage]);

  const [ID, setID] = useState('');
  const [nama, setNama] = useState('');
  const [hak_akses, setHakAkses] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [edit, setedit] = useState(false);
  const editClose = () => {
    setedit(false);
    setID('');
    setNama('');
    setHakAkses('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  }
  const editShow = (data) => {
    setedit(true);
    setMsg('');
    setID(data.Id);
    setNama(data.Name);
    setHakAkses(data.Akses);
    setEmail(data.Email);
  }

  const [newUserModal, setnewUser] = useState(false);
  const newUserModalClose = () => setnewUser(false);
  const newUserModalShow = () => {
    setnewUser(true);
    setID('');
    setNama('');
    setHakAkses('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  }

  // dapatkan data user
  const getUser = async () => {
    const response = await axios.get(`/admin?page=${currentPage}&limit=${limit}`);
    if (response.data.length) {
      setUser(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // tambah user
  const Tambah = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      await axios.post('/admin', {
        Email: email,
        Password: password,
        Name: nama,
        Akses: hak_akses,
      });
      getUser();
      newUserModalClose();
    } else {
      setMsg('Passwords do not match. Please check again.');
    }
  }

  // edit data user
  const Edit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      await axios.put(`/admin/${ID}`, {
        Email: email,
        Password: password,
        Name: nama,
        Akses: hak_akses,
      });
      getUser();
      editClose();
    } else {
      setMsg('Passwords do not match. Please check again.');
    }
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/admin/pencarian?page=${currentPage}&limit=${limit}&key=${key}`);
      if (response.data.length) {
        setUser(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    } else {
      const response = await axios.get(`/admin?page=${currentPage}&limit=${limit}`);
      if (response.data.length) {
        setUser(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    }
  }


    // hapus data Batuan 
    // Modal
    const [deleteModal, setdetele] = useState(false);
    const deleteModalClose = () => {
      setdetele(false);
      setID('');
    }
      
    const deleteModalShow = (ID) => {
      setdetele(true);
      setID(ID);
    }

    const Hapus = async (e) => {
      e.preventDefault();
      await axios.delete('/admin/' + ID);
      getUser();
      setdetele(false);
    }

  // Id 	Email 	Password 	Name 	Akses 	
  // Export data ke excel
    const header = ["ID", "Email", "Password","User Name","Akses"];

    var currentDate = new Date();
    var sheetName = `DIGITAL OUTCROP-USER ADMIN LIST-${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;


    async function handleDownloadExcel() {
      const response = await axios.get(`/Admin`);
      downloadExcel({
        fileName: sheetName,
        sheet: sheetName,
        tablePayload: {
          header,
          body: response.data ,
        },
      });
    }


  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
    );
  }


  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
        <div className="row justifty-content-center">
          <div className="col-12 MenuContainerLog ps-3">
          <h3 className="subJudul ms-1"><b>User Admin</b></h3>
          <div className="container">
            <div className="row " style={{marginLeft: '-15px'}}>
            <Form onSubmit={filter}>
              <div className="col-3">
                <InputGroup className="mb-1 ">
                  <Form.Control
                    className="CrudSearch"
                    placeholder="Search ..."
                    aria-label="Cari"
                    aria-describedby="basic-addon2"
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                  />
                        <Button className="btn btn-secondary ms-2 btnSearch" type="submit">Search</Button>
                </InputGroup>
              </div>
              </Form>
            </div>
            <div className="row mt-2 " style={{marginLeft: '-20px'}}>
              <div className="col">
                <button type="button" className="btn btn-success ms-2" onClick={newUserModalShow}>Add New User </button>
                <a href='/Log' type="button" className="btn btn-secondary ms-2">Record Log User </a>
                <button  onClick={handleDownloadExcel} type="button" className="btn btn-success ms-2">Export to Excel</button>
              </div>
            </div>
          </div>
            <div className="card m-2" style={{background: '#fff'}}>
              <div className="card-body">
              <div className="table-responsive">
                <table className="table text-center">
                  <thead>
                  {/* Id 	Email 	Password 	FirstName 	LastName 	Bio 	Occupation 	Img 	Status 	link 	 */}
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Access</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {!!User && User ? (
                    User.map((item, i) => (
                      <tr>
                        <th key={item.Id} scope="row">{(currentPage - 1) * limit + i + 1}</th>
                        <td>{item.Name}</td>
                        <td>{item.Email}</td>
                        <td>{item.Akses}</td>
                        <td>

                        <a type="button" className="btn btn-success mt-1" onClick={() => editShow(item)}>Edit</a>
                        <a type="button" className="btn btn-danger ms-3 mt-1" onClick={() => deleteModalShow(item.Id)}>Delete</a>
                        
                        </td>
                      </tr>
                    ))) : (<div>Tidak ada data</div>)}
                  </tbody>
                </table>
                </div>
              </div>
            </div>        
                <div className="pagination ms-2">
                  <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                  {pageNumbers}
                  <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
                </div>
            </div>
        </div>
        </div>
      </div>
      <Footer />
          {/* Delete Modal */}
          <Modal show={deleteModal} onHide={deleteModalClose} size="md">
            <Form className="form-box" onSubmit={Hapus}>
              <Modal.Header closeButton>
                <Modal.Title> Hapus User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <h5 className='textDeleteModal'>
                Are you sure to delete user data ?
              </h5>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={deleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Edit Modal */}
          <Modal show={edit} onHide={editClose} size="lg">
            <Form className="form-box" onSubmit={Edit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit User Admin</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="card ">
                <div className="card-body p-4">
                <div className="form-group">
                {msg ? (
                  <p className="text-error">{msg}</p>
                ) : ('')}

                <label>Name</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    className="searchInput"
                    placeholder="Name"
                    aria-label="Name"
                    aria-describedby="basic-addon2"
                    value={nama}
                    onChange={(e) => setNama(e.target.value)}
                    required
                  />
                </InputGroup>
              
                <label>Access</label>
                <InputGroup className="mb-3 ">
                  <Form.Select aria-label="Default select example" value={hak_akses} onChange={(e) => setHakAkses(e.target.value)} required>
                    <option>Select Access</option>
                    <option value="admin">Admin</option>
                    <option value="root">Super Admin</option>
                  </Form.Select>
                </InputGroup>
              
                <label>Email</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    className="searchInput"
                    placeholder="Email"
                    type="email"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </InputGroup>

                <label>Password</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    type="password" 
                    className="searchInput"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              
                <label>Re-Password</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    type="password" 
                    className="searchInput"
                    placeholder="Re-Password"
                    aria-label="Re-Password"
                    aria-describedby="basic-addon2"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              </div>
                </div>
              </div>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={editClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Edit User
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* New User Modal */}
          <Modal show={newUserModal} onHide={newUserModalClose} size="lg">
            <Form className="form-box" onSubmit={Tambah}>
              <Modal.Header closeButton>
                <Modal.Title>Add New User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="card ">
                <div className="card-body p-4">
                <div className="form-group">
                {msg ? (
                  <p className="text-error">{msg}</p>
                ) : ('')}
                <label>Name</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    className="searchInput"
                    placeholder="Name"
                    aria-label="Name"
                    aria-describedby="basic-addon2"
                    value={nama}
                    onChange={(e) => setNama(e.target.value)}
                    required
                  />
                </InputGroup>
              
                <label>Access</label>
                <InputGroup className="mb-3 ">
                  <Form.Select aria-label="Default select example" value={hak_akses} onChange={(e) => setHakAkses(e.target.value)} required>
                    <option>Select Access</option>
                    <option value="admin">Admin</option>
                    <option value="root">Super Admin</option>
                  </Form.Select>
                </InputGroup>

                <label>Email</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    className="searchInput"
                    placeholder="Email"
                    type="email"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </InputGroup>

                <label>Password</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    type="password" 
                    className="searchInput"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              
                <label>Re-Password</label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    type="password" 
                    className="searchInput"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon2"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={newUserModalClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Add New User
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
    </div>
  );
}

export default User;
