import React, { useState, useEffect,useRef } from "react";
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import MapSetting from './map';
import { Button, Modal, Form } from 'react-bootstrap';
import axios from "axios";
function Setting() {
  const [FilterMap, setFilterMap] = useState([]);
  const [posting, setPosting] = useState([]);
  const [HideMap, setHideMap] = useState([]);
  const [mapSettingKey, setMapSettingKey] = useState(0);

  useEffect(() => {
    document.title = "Digital Outcrop | Map Setting";
    getFilter();
    getPosting();
  }, []);
    // dapatkan data Batuan 
    const getFilter = async () => {
    const response = await axios.get(`/terrain`);
    setFilterMap(response.data);
  }

  const [filter, setFilter] = useState(false);
  const filterClose = () => setFilter(false);
  const filterShow = () => setFilter(true);
  
  const [upload, setUpload] = useState(false);
  const uploadClose = () => setUpload(false);
  const uploadShow = () => setUpload(true);


  const mapInstance = React.useRef();
  const mapApi = React.useRef();

  const reRenderMapSetting = () => {
    setMapSettingKey((prevKey) => prevKey + 1);
  };

  const apiHasLoaded =  async ({ map, maps }) => {
    var DumpMap = [];
    for (let index = 0; index < posting.length; index++) {
      let coordinate = posting[index].post.Coordinate.split(',');
      // console.log(posting[index].properties.Batuan);
      let batuan = posting[index].properties.Batuan;
      // // Coordinates for your specific location
      const lat = Number(coordinate[0]);
      const lng = Number(coordinate[1]);
    
    //   const pinBackground = new PinElement({
    //     background: '#FBBC04',
    // });

      const marker = new maps.Marker({
        position: new maps.LatLng(lat, lng),
        map,
        title: posting[index].post.Title, // Set a title for the marker

        icon: {
          path:'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z',
          fillColor: batuan.color.toLowerCase(), // Set the fill color to blue
          fillOpacity: 1,
          strokeColor: 'white', // Set the border color
          strokeWeight: 2,
          scale: 1.2, // Adjust the size as needed
          anchor: new maps.Point(20, 35),
        },

      });
      // fillColor: batuan.color.toLowerCase(), // Set the fill color to blue
    
      // Add a click event listener to display info when the marker is clicked
      marker.addListener('click', () => {
        const infoWindow = new maps.InfoWindow({
          content: `
          <div>
            <p>${posting[index].post.Title}</p>
          </div>`,
        });
        infoWindow.open(map, marker);
      });
    }
    

  for (let g = 0; g < FilterMap.length; g++) {
  const targetValue = FilterMap[g].ID;
  let data_hidden = HideMap;

  if (mapSettingKey === 0) {
    
      if(HideMap.indexOf(targetValue) !== -1)   { 
        let index =HideMap.indexOf(targetValue);
        data_hidden.splice(index, 1); 
      }else{
        data_hidden.push(targetValue);
      }
        setHideMap(data_hidden);

  }
    
  let temp  = [{
    idRegion:FilterMap[g].ID,
    coordinates_data:[]
  }]

  
  if(HideMap.indexOf(targetValue) == -1)   {
    console.log('ini ID :',FilterMap[g].ID, targetValue);
    console.log(HideMap.indexOf(targetValue));
  const datajson = await axios.get('/'+FilterMap[g].Data+'');
  var dataMap = datajson.data;

  // Set instance map dan mapApi
  mapInstance.current = map;
  mapApi.current = maps;

    // Render polygons only if their ID is not in the HideMap array
    // Your code for rendering polygons...
      // Loop melalui fitur-fitur pada FilterMap
      for (let i = 0; i < dataMap.features.length; i++) {
        // Ambil koordinat geometri fitur
        let coordinate = dataMap.features[i].geometry;
        let geometries_data = '';

        try {
          geometries_data = dataMap.features[i].geometry.geometries;
        } catch (error) {
          geometries_data = 'err';
        }


        let properties = dataMap.features[i].properties;

        let coordinates = coordinate.coordinates;
        let type = dataMap.features[i].geometry.type;


        if (geometries_data != undefined) {
          console.log('yes');
          // console.log(geometries_data);
          geometries_data.forEach(geometriesIndex => {
            const ringWithLatLng = [];
            console.log(geometriesIndex.coordinates);
            geometriesIndex.coordinates[0].forEach(coordinate => {
              const [lng, lat] = coordinate.map(parseFloat);
              if (!isNaN(lat) && !isNaN(lng)) {
                ringWithLatLng.push({ lat, lng });
              }
            });
            // console.log(ringWithLatLng);
            MapisPolygon(ringWithLatLng,properties);

          });
        }


      // Periksa jenis geometri (Polygon atau MultiPolygon)
      if (type === 'Polygon') {
        coordinates.forEach(ring => {
          const ringWithLatLng = [];
          
          ring.forEach(coordinate => {
            const [lng, lat] = coordinate.map(parseFloat);
            if (!isNaN(lat) && !isNaN(lng)) {
              ringWithLatLng.push({ lat, lng });
            }
          });
          MapisPolygon(ringWithLatLng,properties);
        });
      }
      

      if (type === 'MultiPolygon') {
        coordinates.forEach(polygon => {
          const polygonWithLatLng = [];
          
          polygon.forEach(ring => {
            const ringWithLatLng = [];
            
            ring.forEach(coordinate => {
              const [lng, lat] = coordinate.map(parseFloat);
              if (!isNaN(lat) && !isNaN(lng)) {
                ringWithLatLng.push({ lat, lng });
              }
            });      
            polygonWithLatLng.push(ringWithLatLng);
            mapIsMultiPolygon(ringWithLatLng,properties); // Apply ke google map
          });
        });
      }


      
          }
     
        

          // dsd
    
  }

  function MapisPolygon(paths,properties) {
    // Buat objek Polygon
    // console.log('properies :', properties);
    let fill =  properties.fill;
    let stroke = properties.stroke;

    if (fill == undefined || fill == '') {
      fill = '#000';
      stroke = '#000';
    }


    const polygon = new mapApi.current.Polygon({
      paths: paths,
      geodesic: true,
      strokeColor: stroke,
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: fill,
      fillOpacity: 0.4,
      content: properties.name, // Isi dengan konten yang diinginkan
    });
  
    // Tambahkan event click pada polygon
      polygon.addListener("click", () => {
      // Set konten InfoWindow sesuai dengan nama yang diinginkan
      // Buka InfoWindow pada posisi pertama dalam paths
      new mapApi.current.InfoWindow({
        content: properties.name,
        position: paths[0], //  dapat memilih titik mana saja dalam polygon sebagai posisi
      }).open(mapInstance.current);
    }); 
    // Tampilkan polygon pada peta
      polygon.setMap(map);
  
      
  }
  
  
  function mapIsMultiPolygon(paths,properties) {


    let fill =  properties.fill;
    let stroke = properties.stroke;

    if (fill == undefined || fill == '') {
      stroke = '#000';
      fill = '#141414';
    }


        // Buat objek Polygon
        const multipolygon = new mapApi.current.Polygon({
          paths: paths,
          geodesic: true,
          strokeOpacity: 1,
          strokeWeight: 1,
          strokeColor: stroke,
          fillColor: fill,
          fillOpacity: 0.4,
          content: properties.name, // Isi dengan konten yang diinginkan
        });
      
        // Tambahkan event click pada polygon
        multipolygon.addListener("click", () => {
          // Set konten InfoWindow sesuai dengan nama yang diinginkan
          // Buka InfoWindow pada posisi pertama dalam paths
          new mapApi.current.InfoWindow({
            content: properties.name,
            position: paths[0], //  dapat memilih titik mana saja dalam polygon sebagai posisi
          }).open(mapInstance.current);
        }); 
        // Tampilkan polygon pada peta
        multipolygon.setMap(map);
    }
   
}

//  new maps.KmlLayer('https://103.123.62.36:5000/Geology_Basin_West_Kalimantan.kml', {
//   suppressInfoWindows: true,
//   preserveViewport: false,
//   map: map
// });

//  new maps.KmlLayer('https://103.123.62.36:5000/Tarakan_Basin_Geology.kml', {
//   suppressInfoWindows: true,
//   preserveViewport: false,
//   map: map
// });

// new maps.KmlLayer('https://ghozi.neetkode.com/assets/img/Tarakan_Basin_Geology.kml', {
//   suppressInfoWindows: true,
//   preserveViewport: false,
//   map: map
// });

};







const handlePolygonHidden = async (id) => {

  let data_hidden = HideMap;

  if(HideMap.indexOf(id) !== -1)   { 
    let index =HideMap.indexOf(id);
    data_hidden.splice(index, 1); 
  }else{
    data_hidden.push(id);
  }

  console.log(data_hidden);
    setHideMap(data_hidden);
    reRenderMapSetting();

}



//------------------------------------------- CRUD Filter -------------------------------------------

const [ID, setID] = useState('');
const [NameFile, setNameFile] = useState('');
// Tambah Crud
// Name 	Location 	Description 	Data 	
const [Name, setName] = useState('');
const [Location, setLocation] = useState('');
const [Description, setDescription] = useState('');
const [Data, setData] = useState('');
const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const Tambah = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      return;
    }
    
    const data = new FormData();
    data.append('Name', Name);
    data.append('Location', Location);
    data.append('Description', Description);
    data.append('file', selectedFile);
    try {
      await axios.post('/terrain', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      uploadClose();
      getFilter();

    } catch (error) {
      console.error('Error uploading terrain:', error);
    }
  };

  // Hapus 
  // hapus data Setting
      // Modal
      const [deleteModal, setdetele] = useState(false);
      const deleteModalClose = () => {
      setdetele(false);
      setID('');
      }
      
      const deleteModalShow = (ID,Data) => {
      setdetele(true);
      setNameFile(Data);
      setID(ID);
      }

      const Hapus = (ID,NameFile) => {
        axios.delete('/terrain/' + ID +'/'+ NameFile );
        getFilter();
        setdetele(false);
      }

   
      // Edit
      // Edit Data setting
      const [editSetting, seteditSetting] = useState(false);
      const editClose = () => {
        seteditSetting(false);
        setName('');
        setLocation('');
        setDescription('');
        setData('');
        setSelectedFile('');

      }

      const editShow = (data) => {
        seteditSetting(true);
        setID(data.ID);
        setName(data.Name);
        setLocation(data.Location);
        setDescription(data.Description);
        setData(data.Data);
      }

      
      const Edit = async (e) => {
        e.preventDefault();
        await axios.put(`/terrain/${ID}`, {
          Name: Name,
          Location: Location,
          Description: Description,
          Data: Data,
        });
        getFilter();
        editClose();
      }

      // Get posting
        // dapatkan data UserPost 
      const getPosting = async () => {
        // const response = await axios.get(`/posting`);
        const response = await axios.get(`/userpost/?limit=9999999999&page=1`);
        if (response.data.length) {
          setPosting(response.data);
        }
      }



  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
          <h3> <b>Map Setting</b></h3>
          <div className="row">
            <div className="col-sm-6 mb-6 mb-sm-0">
              <div className="row ">
                <div className="col-sm-12 mb-3 mb-sm-0">
                  <button type="button" className="btn btn-success mt-2 ms-1" onClick={filterShow}>Map Filter</button>
                  <button type="button" className="btn btn-success mt-2 ms-1" onClick={uploadShow}>Upload Terrain</button>
              </div>
        
            </div>
            </div>
            <div className="col-sm-6"></div>
          </div>
    
          <div className='mt-5 mb-3' style={{ height: '100vh', width: '100%' }}>
          <MapSetting
           key={mapSettingKey}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={apiHasLoaded}
              // onGoogleApiLoaded={({ apiHasLoaded }) => {
              //   // Do whatever you want using the map, and maps objects
              //   let kmlLayer = maps.KmlLayer('https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml', {
              //     suppressInfoWindows: true,
              //     preserveViewport: false,
              //     map: map
              //   });
              // }}
            />
          </div>

          {/* Map Filter */}
          <Modal show={filter} onHide={filterClose} size="sm">
            <Form className="form-box">
              <Modal.Header closeButton>
                <Modal.Title>  Map Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {!!FilterMap && FilterMap ? (
                      FilterMap.map((item, i) => (                   
                    <div>
                      <Form.Group>
                        <Form.Label   key={item.ID}  className="crud-label">
                          <Form.Check  
                          type="switch" 
                          id={item.Name} 
                          label={item.Name} 
                          defaultChecked={!HideMap.includes(item.ID)} 
                          // defaultChecked={true} 
                          onChange={() => handlePolygonHidden(item.ID)} // Toggle the visibility when the switch is clicked
                        />
                        </Form.Label>
                        <button type="button" className="btn btn-sm btn-success mt-2 ms-1" onClick={() => editShow(item)}>Edit</button>
                        <button type="button" className="btn btn-sm btn-danger mt-2 ms-1"   onClick={() => deleteModalShow(item.ID,item.Data)}>Delete</button>
                      </Form.Group>
                      <hr></hr>
                    </div>  
               ))) : (<div>Tidak ada data</div>)}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={filterClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Upload Terrain */}
          <Modal show={upload} onHide={uploadClose}>
            <Form className="form-box" onSubmit={Tambah}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Terrain</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label className="crud-label mt-1">Nama Terrain</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder='Nama Terrain'
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  required />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="crud-label mt-1">Location</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder='Location' 
                  value={Location}
                  onChange={(e) => setLocation(e.target.value)}
                  required />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="crud-label mt-1">Description</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder='Description' 
                  required 
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  />
            
                </Form.Group>
                <Form.Group>
                <input   className='mt-3 btn btn-secondary'  type="file" onChange={handleFileChange} />
                  {/* <Button className='mt-3' variant="success" type="file" onChange={handleFileUpload} >Upload Terrain</Button> */}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={uploadClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

    
            {/* Modal Delete */}
            <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
            <Form className="form-box">
              <Modal.Header closeButton>
                <Modal.Title> Delete Terrain</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <h5 className='textDeleteModal'>
                Are you sure to delete Terrain data ?
              </h5>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={deleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={() => Hapus(ID,NameFile)}>
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

           <Modal show={editSetting} onHide={editClose}>
            <Form className="form-box" onSubmit={Edit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Terrain</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label className="crud-label mt-1">Nama Terrain</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder='Nama Terrain'
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  required />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="crud-label mt-1">Location</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder='Location' 
                  value={Location}
                  onChange={(e) => setLocation(e.target.value)}
                  required />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="crud-label mt-1">Description</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder='Description' 
                  required 
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  />
            
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={editClose}>
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>



        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Setting;
