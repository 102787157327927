import React from 'react';

function Footer() {
    return(
      <div className='bg-footer'>
        <div className="justify-content-md-center">
          <div className="text-footer" style={{ fontWeight: 300,fontSize:12 }}>Copyright <a className="text-decoration-none text-white" href="#" style={{ fontWeight: 300,fontSize:12 }}> 2023 Upstream Innovation.Pertamina Hulu Energi</a></div>
        </div>
      </div>
    );
}

export default Footer;