import React, { useState, useEffect } from "react";
// import { Input, InputAdornment, IconButton } from "@mui/material";
import { Form, Button,InputGroup,Modal  } from "react-bootstrap";
import { useParams,Link } from 'react-router-dom';
import '../App.css';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Label } from "@mui/icons-material";
import thumbnail from '../assets/img/thumbnail.jpg';
import axios from "axios";



function PostDetail() {
  const { id } = useParams();
  const [PostDetail, setPostDetail] = useState([]);
  const [imagePost,setImagePost] = useState([]);

  useEffect(() => {
    document.title = "Digital Outcrop | Post Detail";
    getUserPosting();
    getImagePosting();
  }, []);

     // dapatkan data user Posting
    const getUserPosting = async () => {
      const response = await axios.get(`/posting/${id}`);
      // console.log(response.data[0]);
      setPostDetail(response.data[0]);

    }
    const getImagePosting = async () => {
      const response = await axios.get(`/photopost/${id}`);
      setImagePost(response.data);
    }



  return (
    <div className='bg-dashboard'>
    <Header />
    <div className='tinggi-content'>
      <div className='container'>
        <div className="row justify-content-center mt-2">
            <div className="col-sm-8 mb-3 mb-sm-0">
                <h3 className="text-bold"><b>Post Detail</b> </h3>
              <div className="card ">
                <div className="card-body ">

                    <p> <b>{PostDetail.Title}</b></p>
                    <hr></hr>
                    {/* Id 	UserID 	Coordinate 	azimuth 	Batuan 	Title 	description_id 	Date 	StrikeDip 	 */}
                    {/* azimuth	StrikeDip	Batuan */}
                    <p>
                        User : {PostDetail.FirstName} <br></br>
                        Coordinate : {PostDetail.Coordinate}<br></br>
                        azimuth : {PostDetail.azimuth}<br></br>
                        StrikeDip : {PostDetail.StrikeDip}<br></br>
                        Lithology : {PostDetail.Batuan}<br></br>
                      
                    </p>
                   <hr></hr>
           
                    <div className="row justify-content-center">

                    {!!imagePost && imagePost ? (
                      imagePost.map((item, i) => (   

                    <div className="col-md-5 col-sm-12">
                        <img src={'https://86.38.203.172:4000/assets/image/'+item.Image} style={{ height: "100px" }} alt="thumbnail1" />
                        <br></br>
                        <b> Description :</b> <br></br>
                       {item.description}
                    </div>

                    ))) : (<div>Tidak ada data</div>)}
                      

                    </div>
                </div>
                
              </div>
            </div>
          
        </div>
     
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default PostDetail;
