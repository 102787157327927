import React, { useState, useEffect } from "react";
import '../assets/css/login.css';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Input, InputAdornment, IconButton } from "@mui/material";
import axios from "axios";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      document.title = "Admin Merapi | Login";
    }, []);
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    }
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post('/loginAdmin', {
          Email: email,
          Password: password
        }).then(response => {
          sessionStorage.setItem('data_token', response.data.token);
          sessionStorage.setItem('expired_token', Date.now() + 2 * 60 * 60 * 1000);
          sessionStorage.setItem('id', response.data.user.Id);
        });
        navigate('/');
      } catch (error) {
        console.log(error);
        if (error.response) {
          setMsg(<h4 className='pesan-error'>{error.response.data.msg}</h4>);
        }
      }
    }
  
    return (
        <div className="Login">
          <div className="card-login">
            <form onSubmit={handleSubmit}>
              <div className="logo-login">
                <img src={require('../assets/img/3.png')} style={{ width: '100px' }} alt="logo" />
              </div>
              <p className="judul-login mt-5">Sign In</p>
              <div className="form-group">
                <Input type="email" className="input-login" placeholder="Email" value={email} onChange={handleEmailChange} />
              </div>
              <div className="form-group">
                <div className="d-flex position-relative">
                  <Input className="input-login" placeholder="Password" value={password} onChange={handlePasswordChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    } />
                </div>
              </div>
              <button className="button-login mb-2 mt-3" type="submit">Login</button>
            </form>
          </div>
        </div>
      );
}

export default Login;