import React, { useState, useEffect } from "react";
// import { Input, InputAdornment, IconButton } from "@mui/material";
import { Form, Button,InputGroup,Modal  } from "react-bootstrap";
import { useParams,Link } from 'react-router-dom';
import axios from "axios";

import '../App.css';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Label } from "@mui/icons-material";


function ReportDetail() {
  const { id } = useParams();
  const [UserReport, setUserReport] = useState([]);

  useEffect(() => {
    document.title = "Digital Outcrop | Report Detail";
    getUserReport();
  }, []);

     // dapatkan data user Report
     const getUserReport = async () => {
      const response = await axios.get(`/report/${id}`);
      setUserReport(response.data);
    }

  
  return (
    <div className='bg-dashboard'>
    <Header />
    <div className='tinggi-content'>
      <div className='container'>
        <div className="row justify-content-center mt-2">
            <div className="col-sm-7 mb-3 mb-sm-0">
                <h3 className="text-bold"><b>Report Detail</b> </h3>
              <div className="card ">
                <div className="card-body p-4">

                    <p>Reason : {UserReport.reason} - {UserReport.timestamp}</p>
                    <hr></hr>
                    <p>
                        User : {UserReport.FirstName} {UserReport.LastName}<br></br>
                        Institution : {UserReport.Institution}<br></br>
                        Role : Student<br></br>

                    </p>

                    <Link to={`/Reported/`}>
                        <a type="button" className="btn btn-secondary me-1 mt-1">Back</a>
                    </Link>
                   
                </div>
                
              </div>
            </div>
          
        </div>
     
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default ReportDetail;
