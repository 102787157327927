import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import PrivateRoute from './PrivateRoute';
import Beranda from './beranda/beranda';
import Setting from './setting/setting';
import User from './user/user';
import UserApp from './user/userApp'

import Log from './user/log';
import Visitor from './visitor/visitor';
import Profile from './user/profile'
import Post from './posting/post'
import Report from './report/report'
import ReportDetail from './report/reportDetail'
import PostDetail from './posting/postDetail'
import Batuan from './batuan/batuan'
import Login from './login/login';
import Landing from './landing/landing';
import Home from './frontPage/Home'



function App() {
  return (
    <BrowserRouter>
        <Routes>

          <Route path="/login"  element={<Login />} />
          <Route exact path='/' element={<PrivateRoute />}>
            <Route path="/" element={<Beranda />} />
            <Route path="/Beranda"  element={<Beranda />} />
            <Route path='/Portofolio' element={<Post />} />
            <Route path="/log" element={<Log />} />
            <Route path="/log/:id" element={<Log />} />
            <Route path="/Visitor" element={<Visitor />} />
            <Route path='/Setting' element={<Setting />} />
            <Route path='/User' element={<User />} />
            <Route path='/UserApp' element={<UserApp />} />
            <Route path='/Profile' element={<Profile />} />
            <Route path='/Rock' element={<Batuan />} />
            <Route path='/PostDetail/:id' element={<PostDetail />} />
            <Route path='/Reported' element={<Report />} />
            <Route path='/ReportDetail/:id' element={<ReportDetail />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
