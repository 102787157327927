import React from "react";
import GoogleMapReact from 'google-map-react';

const customizeMap = [
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

export default function MapSetting({
  defaultCenter = { lat: -7.796467, lng: 110.500783 },
  libraries = [],
  children,
  ...rest
}) {
  return (
    <GoogleMapReact
      {...rest}
      bootstrapURLKeys={{
        key: "AIzaSyCFBoSVkMAz4DX3EFXMGOyy60RSK_F-yK0",
        libraries
      }}
      options={{
        styles: customizeMap,
        mapTypeControl: true,
        mapTypeId: 'satellite',
      }}
      defaultCenter={{ lat: -7.747872, lng: 110.4218147 }}
      defaultZoom={10}
      
    >
      {children}
    </GoogleMapReact>
  );
}
