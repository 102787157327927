import React, { useState, useEffect } from "react";
import { Form, Button,InputGroup, Modal   } from "react-bootstrap";
import axios from "axios";
import { useParams } from 'react-router-dom';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function Log() {
  const [ID, setID] = useState('');
  const [LogAdmin, setLogAdmin] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  let { id } = useParams();

  useEffect(() => {
    document.title = "Digital Outcrop | User Log";
    getAdminLog();
  }, []);

    // dapatkan data user Report
    const getAdminLog = async () => {
      try {
        const response = await axios.get(`/log?page=${currentPage}&limit=${limit}&id=${id}`);
        if (response.data.length) {
          setLogAdmin(response.data[0]);
          setTotal(Math.ceil(response.data[1].jumlah / limit));
        }
      } catch (error) {
        console.log('tidak ada data');
      }
    }

  // hapus data Batuan 
    // Modal
    const [deleteModal, setdetele] = useState(false);
    const deleteModalClose = () => {
      setdetele(false);
      setID('');
    }
      
    const deleteModalShow = (ID) => {
      setdetele(true);
      setID(ID);
    }

    const Hapus = async (e) => {
      e.preventDefault();
      await axios.delete('/log/' + ID);
      getAdminLog();
      setdetele(false);
    }

    // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/log/pencarian?page=${currentPage}&limit=${limit}&key=${key}&id=${id}`);
      setLogAdmin(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/log?page=${currentPage}&limit=${limit}&id=${id}`);
      setLogAdmin(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getAdminLog();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
    );
  }

  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
        <div className="row justifty-content-center">
          <div className="col-12 MenuContainerLog ps-3">
          <h3 className="subJudul ms-1"><b>Admin Log</b></h3>
          <div className="container">
            <div className="row " style={{marginLeft: '-15px'}}>
              <Form onSubmit={filter}>
                    <div className="col-3">
                      <InputGroup className="mb-1 ">
                        <Form.Control
                          className="CrudSearch"
                          placeholder="Search ..."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                      <Button className="btn btn-secondary ms-2 mt-1 btnSearch" type="submit">Search</Button>
                      </InputGroup>
                    </div>
                  </Form>
              <div className="col"></div>
            </div>
          </div>
            <div className="card m-2" style={{background: '#fff'}}>
              <div className="card-body">
              <div className="table-responsive">
                <table className="table text-center">
                  <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Name</th>
                          <th scope="col">Activity</th>
                          <th scope="col">Timestamp</th>
                          <th scope="col">Action</th>
                        </tr>
                  </thead>
                  <tbody>

                  {!!LogAdmin && LogAdmin ? (
                      LogAdmin.map((item, i) => (                   
                    <tr>
                      <th key={item.id} scope="row">{i + 1}</th>
                      <td>{item.Name}</td>
                      <td>{item.Activity}</td>
                      <td>{item.Timestamp}</td>
                      <td><button type="button" className="btn btn-danger"  onClick={() => deleteModalShow(item.id)}>Delete</button></td>
                    </tr>
                    ))) : (<div>Tidak ada data</div>)}
                  </tbody>
                </table>
                </div>
              </div>
            </div>        
              <div className="pagination ms-2">
                <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                {pageNumbers}
                <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
              </div>
            </div>
        </div>
        </div>
      </div>
      <Footer />

      <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
            <Form className="form-box" onSubmit={Hapus}>
              <Modal.Header closeButton>
                <Modal.Title> Delete Log</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className='textDeleteModal'>
                  Are you sure to delete Log data ?
                </h5>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={deleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
    </div>
  );
}

export default Log;
