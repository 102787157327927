// import React from 'react';
import { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/img/3.png';
import '../assets/css/style.css';

function Header() {
  // Navbar Horizontal
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setSmShow(false);
  const handleShow = () => setSmShow(true);
  const [isActive, setIsActive] = useState(false);
  const handleImageClick = () => {
    setIsActive(!isActive); // Toggle the active state
  };
  // logout
  // const logOut = () => {
  //   sessionStorage.removeItem('data_token');
  //   sessionStorage.removeItem('expired_token');
  //   sessionStorage.removeItem('id');
  // } 
  const logOut = () => {
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('x-access-token-expiration');
    localStorage.removeItem('id_user');
    sessionStorage.removeItem('data_token');
    sessionStorage.removeItem('expired_token');
    sessionStorage.removeItem('id');
  }
  const id_user = sessionStorage.getItem('id');
  return (
    <div className="nav-header" id="page-top">
      <Navbar collapseOnSelect expand="lg" variant="light" className="shadow rounded" id="mainNav">
        <Container>
          <a className="text-header text-decoration-none" href='/'>
            <span style={{ fontSize:"17px",marginTop:'40px',marginLeft:'10px' }}>Admin Merapi</span>
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" />
            <Nav className="ml-auto nav-pills" >
              <NavLink to="/" className={({ isActive }) => isActive ? 'text-decoration-none text-nav me-lg-3 aktif ' : ' text-decoration-none text-nav me-lg-3 un-aktif '}> Home</NavLink>
             
              <NavLink to="/Portofolio" className={({ isActive }) => isActive ? 'text-decoration-none text-nav me-lg-3 aktif' : 'text-decoration-none text-nav me-lg-3 un-aktif'}> Portofolio</NavLink>

              <NavLink to="/User" className={({ isActive }) => isActive ? 'text-decoration-none text-nav me-lg-3 aktif' : 'text-decoration-none text-nav me-lg-3 un-aktif'}> User Admin</NavLink>
              <NavLink to="/Visitor" className={({ isActive }) => isActive ? 'text-decoration-none text-nav me-lg-3 aktif' : 'text-decoration-none text-nav me-lg-3 un-aktif'}> Visitor</NavLink>
            </Nav>
            <span className="text-nav" >
              <img id="image" src={Logo} className="rounded mt-3" width="25px" alt="" onClick={handleImageClick} />
              <NavDropdown id="navbarScrollingDropdown" className="dropdownStyle" title="" show={isActive} >
                <NavDropdown.Item className='text-black' href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item  onClick={logOut}  className='text-black' href="/login">Logout</NavDropdown.Item>
              </NavDropdown>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;