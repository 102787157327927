import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';


export default function PrivateRoute(props) {
  const auth = sessionStorage.getItem("data_token");

  if (auth) {
    return <Outlet />
  } else {
    return <Navigate to={'/login'} />
    // return <Navigate to={'/'} />
  }
}