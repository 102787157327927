import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup, Modal,FloatingLabel } from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import axios from "axios";
import '../App.css';
import '../assets/css/style.css';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import Compressor from 'compressorjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function Post() {
  const [Posting, setPosting] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  const [ID, setID] = useState('');
  // const base_url = 'http://192.168.100.86:4000.com/';
  // const base_url = 'http://localhost:4000/';

  const base_url = 'https://backend.merapikoding.com/';
// 
  const [startDate, setStartDate] = useState(new Date());

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  useEffect(() => {
    document.title = "Admin Merapi | Posting";
    getPosting();

  }, []);

  useEffect(() => {
    getPosting();
  }, [currentPage]);

  const [SeeImageModal, setSeeImage] = useState(false);
  const [newPostModal, setNewPostImage] = useState(false);
  const [postStatus, setPostStatus] = useState('');


   // dapatkan data Batuan 
    const getPosting = async () => {
      const response = await axios.get(`/posting?page=${currentPage}&limit=${limit}`);
      if (response.data.length) {
        setPosting(response.data[0]);
        setTotal(Math.ceil(response.data[1].jumlah / limit));
      }
    }

   // hapus data Batuan 
    // Modal
    const [deleteModal, setdetele] = useState(false);
    const deleteModalClose = () => {
      setdetele(false);
      setID('');
    }
      
    const deleteModalShow = (ID) => {
      setdetele(true);
      setID(ID);
    }

    const Hapus = async (e) => {
      e.preventDefault();
      await axios.delete('/posting/' + ID);
      getPosting();
      setdetele(false);
    }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/posting/pencarian?page=${currentPage}&limit=${limit}&key=${key}`);
      setPosting(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/posting?page=${currentPage}&limit=${limit}`);
      setPosting(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <a className={currentPage === i ? 'active' : ''} onClick={() => Pagination(i)}>{i}</a>
    );
  }

  // See Image
  const [ListImages, setListImages] = useState([]);
  const [IDPost, setIDPost] = useState([]);

  const GetListImage = async (id) => {
  
    const response = await axios.get(`/photopost/${id}`);
    // console.log(response.data);
    setListImages(response.data);
  }

  const seeImage = (id) => {
 
    setIDPost(id);
    setSeeImage(true);
    GetListImage(id);

  }
  const CloseImages = (id) => {
    setIDPost('');
    setSeeImage(false);

  }

  const [idPosting, setIdPosting] = useState(0);
  const modalPost = (button,postingId) => {

  
    if (button === 'post') {
      setNewPostImage(true);
      setPostStatus('post')
      
    }else{
      
      setPostStatus(button)
      setNewPostImage(true);
      setIdPosting(postingId);

    }

  }

  const ClosePost = () => {
    // setIDPost('');
    setNewPostImage(false);

  }


  const OpenTag = () => {
    // setIDPost('')
    setTagModal(true);
  }
  const CloseTag = () => {
    // setIDPost('')
    setTagModal(false);
  }

  const FormatTanggal = (waktu) => {
    const dateObject = new Date(waktu.timestamp);
    // Get day, month, and year
    const day = dateObject.getDate();
    const monthIndex = dateObject.getMonth();
    const monthName = monthNames[monthIndex];
    const year = dateObject.getFullYear();

    return `${day} ${monthName} ${year}`;

  }

  
  // ==========================================================================================
  // New Post
  const [tagModal, setTagModal] = useState(false);


  
  // use state for new post 	
  const [kategori, setKategori] = useState([]);
  const [title, setTitle] = useState([]);
  const [msg, setMsg] = useState([]);
  const [date, setDate] = useState([]);
  const [teknologi, setTeknologi] = useState([]);
  const [client, setClient] = useState([]);
  const [url, setUrl] = useState([]);
  const [fitur, setFitur] = useState([]);
  const [dokumen, setDokumen] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  //function to clear all fields
  const clearAllFields = () => {
    setKategori('');
    setTitle('');
    setMsg('');
    setDate('');
    setTeknologi('');
    setClient('');
    setUrl('');
    setFitur('');
    setDokumen('');
    setSelectedFile(null);
  }

  


 
  const [errMsg, setErrMsg] = useState('');

   
  // Add New Image
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageDeskripsi, setImageDeskripsi] = useState([]);
  const [imageID,setImageID] = useState(0);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const file = event.target.files[0];
    const newImages = [];
    // console.log('asdsadadsad', file);

    let DumpData = imageDeskripsi || []; // Initialize with an empty array if imageDeskripsi is undefined

    var image64 ='';
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onloadend = () => {
        newImages.push(reader.result);
        // console.log('reader', reader.result);
        image64 = reader.result;
        if (newImages.length === files.length) {
          // setSelectedImages((prevImages) => [...prevImages, ...newImages]);
          setSelectedImages(newImages);
          let dumpID = imageID + 1;
          let dataImage = [{ ID: dumpID, url_image: file, deskripsi: "", base64:reader.result }];
          setImageID(dumpID);
          DumpData.push(dataImage);
          // console.log(DumpData);
          setImageDeskripsi(DumpData);

        }


      };

      if (files[i]) {
        reader.readAsDataURL(files[i]);
      }
    }

  };

    function inputDeskripsi(text,id) {

         let deskripsi_dump = imageDeskripsi;
    
          deskripsi_dump[id][0].deskripsi = text;
          // console.log(deskripsi_dump);
          setImageDeskripsi(deskripsi_dump);
        
    }

    const [IDPhotoDelete, setIDPhotoDelete] = useState([]);


    const removeImage = (idToDelete) => {
     
 
      const updatedData = imageDeskripsi.map(innerArray => {
        // Get array index of item
        const filteredArray = innerArray.filter(item => item.ID !== idToDelete);
        const filteredArrayD = innerArray.filter(item => item.ID === idToDelete);
    
        if (filteredArrayD.length > 0 && filteredArrayD[0].base64 === "") { // Check if filteredArrayD is not empty
            let DumpData = [];
            DumpData.push(idToDelete);
            // setIDPhotoDelete();
            // push the ID to the IDPhotoDelete array
            setIDPhotoDelete(prevArray => [...prevArray, ...DumpData]);
            console.log('dump',IDPhotoDelete);
        }
    
        return filteredArray.length > 0 ? filteredArray : null;
    }).filter(innerArray => innerArray !== null);
  
      if (updatedData.length === 0) {
        setSelectedImages(null);
      }

      
      //
      
      setImageDeskripsi(updatedData);
      console.log(updatedData);
    };
  

    // add postingan
    const [tambahButton, setTambahButton] = useState('Add Post');

    const Tambah = async (e) => {
  
      if (selectedImages === null ) return; 
      submitPost();
      return;

    }
  
    async function submitPost() {

      
      if (!selectedFile) {
        return;
      }

      var last_id ='';
      
      const data = new FormData();
      data.append('kategori', kategori);
      data.append('title', title);
      data.append('msg', msg);
      data.append('date', startDate);
      data.append('teknologi', teknologi);
      data.append('client', client);
      data.append('url', url);
      data.append('fitur', fitur);
      data.append('file', selectedFile);
      
      try {
        await axios.post('/posting', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          //  console.log('sukses');
          last_id = response.data.ID;
          console.log('last id :',last_id);
          uploadImage(last_id)
          ClosePost();
          getPosting();
          clearAllFields();
        });
      
  
      } catch (error) {
        console.error('Error uploading terrain:', error);
      }

      
    }




  async function uploadImage(last_id) {
    
    for (let i = 0; i < imageDeskripsi.length; i++) {
      if  (  imageDeskripsi[i][0]  !==  undefined)  {
        
        const data = new FormData();
        data.append('PostID', last_id);
        data.append('description', imageDeskripsi[i][0].deskripsi);
        
        
        
            let imageFiles = imageDeskripsi[i][0].url_image;
           
            // ============================================ 
            // _editimage fungsi untuk edit hanya deskripsi pada table photopost
            // jika user edit gambar maka hapus gambar yang lama lau upload lagi
            // ============================================ 
            if (imageDeskripsi[i][0].base64 === '') {
              _editImage (imageDeskripsi[i][0],last_id) // 
            }else{
              _addImage(data,imageFiles);
           
            }
         }

      }
  }

  async function _editImage (imageProp,LastIDPost) {
    // console.log('id yang di edit',imageProp);
    // hanya edit deskripsi pada photopost
    const id = imageProp.ID
    const url = imageProp.url_image;
    const lastSlashIndex = url.lastIndexOf("/");
    // Extract the substring after the last '/'

    const fileName = url.substring(lastSlashIndex + 1);
    await axios.put(`/photopost/${id}`, {
      ID: id,
      PostID:LastIDPost,
      Image:fileName,
      description:imageProp.deskripsi,
    }).then(response => { 
    //  console.log('sukses update deskripsi photo id :', id);
    });

  }

  async function _addImage (data,imageFiles) {

    const file = imageFiles;
    let oriname = imageFiles.name;
    let typeFiles = imageFiles.type;

    // Delete Photopost jika ada data ada yg di hapus dari database
    // setIDPhotoDelete
    for (let i = 0; i < IDPhotoDelete.length; i++) {
      const id = IDPhotoDelete[i];
      await axios.delete('/photodelete/' + id);
    }

    if (!file) {
      return;
    }
  
    new Compressor(file, {
      quality: 0.5,
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        const formData = new FormData();
        
        imageFiles = formData;

      },
      error(err) {
        // console.log(err.message);
      },
    });


    data.append('file', imageFiles); 
    // Now, you can send the FormData with the resized image to the server using axios
    try {
 
      const response = await axios.post('/photopost', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
        
    } catch (error) {
      // Handle errors
      console.error('Error uploading image:', error);
    
    }
    
  }

  
  // End New Post
  // ==========================================================================================
  // Edit Post

  useEffect(() => {
    if (idPosting != 0) {
      EditPosting();
    }
  }, [idPosting]);

  const EditPosting = async () => {
    clearAllFields();
    const response = await axios.get(`/posting/${idPosting}`);
   
    if (response.data.posting.ID) {
             
                // console.log('asds',response.data[0].post.Batuan);
                setMsg(response.data.posting.msg);
                setTitle(response.data.posting.title);
                setTeknologi(response.data.posting.teknologi);
                setDate(response.data.posting.date);
                setClient(response.data.posting.client);
                setUrl(response.data.posting.url);
                setKategori(response.data.posting.kategori);
                setFitur(response.data.posting.fitur);
                setDokumen(response.data.posting.dokumen);
                setMsg(response.data.posting.msg);

               

                   let imageData = response.data.photo;
                   let DumpData = []; 
                   
                   for (let i = 0; i < imageData.length; i++) {
                    //  let url =  ''+base_url+'assets/image/'+imageData[i].Image;
                     let url =  ''+base_url+'assets/image/'+imageData[i].Image;
                
                     let dataImage = [{
                       ID: imageData[i].ID,
                       url_image: url, 
                       deskripsi: ''+imageData[i].description+'',
                       base64:'' }];
                 
                    //  console.log('deskripsi:',dataImage);
                     DumpData.push(dataImage);
                   }
                  
                       
                   try {
                     setImageDeskripsi(DumpData);
                     setSelectedImages(DumpData[0]);
                
                   } catch (error) {
                    //  console.log(console.error(error));
                   }

              }
    
  }


  // Mengedit data postingan
  const Edit = async (e) => {
    if (selectedImages === null || imageDeskripsi.length == 0) return;
    untaggedEdit();
    return;

  }


  async function untaggedEdit() {
    
     // use this instead
    const data = new FormData();
    data.append('ID', idPosting);
    data.append('kategori', kategori);
    data.append('title', title);
    data.append('msg', msg);
    data.append('date', startDate);
    data.append('teknologi', teknologi);
    data.append('client', client);
    data.append('url', url);
    data.append('fitur', fitur);
    data.append('old_dokumen', dokumen);
    data.append('file', selectedFile);


    // await axios.delete('/photopost/' + idPosting ).then(response => { 
    //   console.log('sukses hapus');
    // });
    
    try {
      await axios.put(`/posting/${idPosting}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {

       
        uploadImage(idPosting)
        ClosePost();
        getPosting();
        clearAllFields();
      }); 

    } catch (error) {
      console.error('Error uploading terrain:', error);
    }


  }

  


  // End Edit Post
  // ==========================================================================================

  return (
    <div className='bg-dashboard'>
      <Header />
      <div className='tinggi-content'>
        <div className='container'>
          <div className="row justifty-content-center ">
            <div className="col-12 MenuContainerLog ps-3">
              <h3 className="subJudul ms-1"><b>List Portofolio</b></h3>
              <div className="container">
                <div className="row " style={{ marginLeft: '-15px' }}>
                  <Form onSubmit={filter}>
                    <div className="col-3">
                      <InputGroup className="mb-1 ">
                        <Form.Control
                          className="CrudSearch"
                          placeholder="Search ..."
                          aria-label="Cari"
                          aria-describedby="basic-addon2"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                        <Button className="btn btn-secondary ms-2 btnSearch" type="submit">Search</Button>
                      </InputGroup>
                    </div>
                  </Form>
                  <div className="col"></div>
                </div>
                <div className="row mt-2 " style={{marginLeft: '-20px'}}>
              <div className="col-auto ">
                <button onClick={() => modalPost('post')}  type="button" className="btn btn-success ms-1">Add New Portofolio </button>
              </div>
            </div>
              </div>
              <div className="card m-2" style={{ background: '#fff' }}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                      
                          <th scope="col" className='text-center align-middle'>No</th>
                          <th scope="col" className='text-center align-middle'>title</th>
                          <th scope="col" className='text-center align-middle'>msg</th>
                          <th scope="col" className='text-center align-middle'>teknologi</th>
                          <th scope="col" className='text-center align-middle'>date</th>
                          <th scope="col" className='text-center align-middle'>Image</th>
                          <th scope="col" className='text-center align-middle'>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                      {!!Posting && Posting ? (
                    Posting.map((item, i) => (
                        <tr>
                          <th key={item.ID} scope="row">{(currentPage - 1) * limit + i + 1}</th>
                          <td>{item.title}</td>
                          <td>{item.msg}</td>
                          <td className='text-center'>{item.teknologi}</td>
                          <td className='text-center'><FormatTanggal timestamp={item.date} /></td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <a type="button" className="btn btn-success mt-1" onClick={() => seeImage(item.ID)} >Show</a>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <a type="button" className="btn btn-secondary mt-1"  onClick={() => modalPost('edit',item.ID)} >Edit</a>
                            {/* <Link to={`/PostDetail/${item.ID}`}>
                              <a  type="button" className="btn btn-success ms-2 me-2 mt-1" >Detail</a>
                            </Link> */}
                              <a type="button" className="btn btn-danger mt-1" onClick={() => deleteModalShow(item.ID)}>Delete</a>
                            </div>
                          </td>
                        </tr>
                        ))) : (<div>Tidak ada data</div>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
                <div className="pagination ms-2">
                  <a className={currentPage === 1 ? 'disabled' : ''} onClick={() => Pagination(currentPage - 1)}>&laquo;</a>
                  {pageNumbers}
                  <a className={currentPage === Total ? 'disabled' : ''} onClick={() => Pagination(currentPage + 1)}>&raquo;</a>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Hapus Modal  */}
      <Modal show={deleteModal} onHide={deleteModalClose} size="sm">
        <Form className="form-box" onSubmit={Hapus}>
          <Modal.Header closeButton>
            <Modal.Title> Delete Portofolio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='textDeleteModal'>
              Are you sure to delete data post ?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={deleteModalClose}>
              Cancel
            </Button>
            <Button variant="danger" type="submit">
              Delete
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* See Image Modal  */}
      <Modal show={SeeImageModal} onHide={CloseImages} size="lg">
        <Form className="form-box">
          <Modal.Header closeButton>
            <Modal.Title> Image Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>

             <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className='text-center align-middle'>No</th>
                          <th scope="col" className='text-center align-middle'>Image</th>
                          <th scope="col" className='text-center align-middle'>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                      {!!ListImages && ListImages ? (
                        ListImages.map((item, i) => (
                        <tr>
                       <th scope="row">{i + 1}.</th>
                          <td><img id="image" src={''+base_url+'assets/image/'+item.Image} className="rounded mt-3" width="100px" alt="" /></td>
                          {/* <td><img id="image" src={''+item.Image} className="rounded mt-3" width="100px" alt="" /></td> */}
                          <td>{item.description}</td>
                        </tr>
                        ))) : (<div>Tidak ada data</div>)}
                      </tbody>
                    </table>
                  </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={CloseImages}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


      {/* New Post Modal */}
   <Modal show={newPostModal} onHide={ClosePost} size="xl">
        <Form className="form-box">
          <Modal.Header closeButton>

          {postStatus === 'post' ? (
            <Modal.Title> New Portofolio</Modal.Title>
            ) : (
              <Modal.Title> Edit Posting</Modal.Title>
            )}
            

          </Modal.Header>
          <Modal.Body>

    <div>


<div className="container">


<div className="row">

  {/* form group Fields */}
    <div className="col-lg-7  col-sm-12">

                <p style={{ color: 'red' }}>{errMsg}</p>
            <label>Title:</label>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchInput"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputGroup>

            <label>Teknologi :</label>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchInput"
                placeholder="isi menggunakan koma. contoh : nodejs,javascript,mysql"
                value={teknologi}
                onChange={(e) => setTeknologi(e.target.value)}
              />
            </InputGroup>

            

            <label>Fitur :</label>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchInput"
                placeholder="isi menggunakan koma. contoh : map,gps,real time post"
                value={fitur}
                onChange={(e) => setFitur(e.target.value)}
              />
            </InputGroup>

            <label>kategori :</label>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchInput"
                placeholder="kategori postingan"
                value={kategori}
                onChange={(e) => setKategori(e.target.value)}
              />
            </InputGroup>

            <label>client :</label>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchInput"
                placeholder="client Project"
                value={client}
                onChange={(e) => setClient(e.target.value)}
              />
            </InputGroup>
            <label>tanggal project :</label>
            <InputGroup className="mb-3">
            <Form.Group controlId="formDate">
            
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy/MM/dd"
              />
            </Form.Group>


            </InputGroup>

        
            <label>Url :</label>
            <InputGroup className="mb-3">
              <Form.Control
                className="searchInput"
                placeholder="Url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </InputGroup>



            <FloatingLabel controlId="floatingTextarea2" label="Description">

                    <Form.Control
                      as="textarea"
                      placeholder="Write Description Image here"
                      style={{ height: '100px' }}
                      value={msg}
                      // onChangeText={text => inputDeskripsi(index.ID,text)}
                      onChange={(e) => setMsg(e.target.value)}
                    />
                </FloatingLabel>

                
                <label className="mt-3">File Dokumentasi :</label>
              
              <div style={{ marginTop: '20px' }}>
                  <input type="file" multiple onChange={handleFileChange} />
              </div>

                
    </div>

  {/* Form Group Photo */}
<div className="col-lg-5  col-sm-12">

          <label>Photo :</label>
    
    <div style={{ marginTop: '20px' }}>
        <input type="file" multiple onChange={handleImageChange} />
    </div>
   

      {imageDeskripsi.map((image, index) => (
         <div className="row">
           <div className="col-12">
      
           {tambahButton !== 'Uploading...' ? (
                <Button className="mt-2" variant="secondary" onClick={() => removeImage(image[0].ID)}>remove Image</Button> 
            ) : (
              null
            )}
            <br></br>
            {image[0].base64 !== '' ? (
              <img
                key={index}
                src={image[0].base64}
                alt={`Selected ${index + 1}`}
                style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '300px' }}
              />
              ) : (
                <img
                  key={index}
                  src={image[0].url_image}
                  alt={`Selected ${index + 1}`}
                  style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '300px' }}
                />
                )}
           </div>
          
           <div className="col-12 mt-2">

            {postStatus != 'post' ? (
              <p>Original Description : {image[0].deskripsi} </p>
              ) : (
              null
            )}
           <FloatingLabel controlId="floatingTextarea2" label="Description">
              <Form.Control
                as="textarea"
                placeholder="Write Description Image here"
                style={{ height: '100px' }}
                // onChangeText={text => inputDeskripsi(index.ID,text)}
                onChange={(e) => inputDeskripsi(e.target.value,index)}
              />
           </FloatingLabel>
           </div>
         </div>
        ))} 

          
          </div>
          
         


        </div>
    </div>

    </div>
         
          </Modal.Body>
          <Modal.Footer>

          {postStatus === 'post' ? (
            <Button variant="success" disabled={imageDeskripsi.length === 0} onClick={Tambah}>
             Add New Portofolio 
            </Button>
          ) : (
             <Button variant="success" disabled={imageDeskripsi.length === 0} onClick={Edit}>
             Edit Portofolio 
            </Button>
          )}


            <Button variant="secondary" onClick={ClosePost}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>




    </div>

  );
}




export default Post;
